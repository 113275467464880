@import "./libs/variables";
@import "./libs/mixing";

body {
  box-sizing: content-box;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  font-family: "Lato", sans-serif;
  &::-webkit-scrollbar {
    width: 0.6em;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 1em;
    background-color: $lehman-blue-color;
  }
  &::-webkit-scrollbar-track {
    background: rgb(207, 207, 207);
  }

  @include response-media-height(smallh) {
    @media (orientation: landscape) {
      display: none;
    }
  }
}
